<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{translate('Stored queries')}}
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-btn
        :loading="details.loading"
        text
        :to="{name: 'create-stored-query'}">
          <v-icon>create</v-icon> {{translate('New stored query')}}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="translate('Search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="details.loading"
          :headers="headers"
          :items="details.storedQueries"
          :search="search">
          <template v-slot:item.internal.edit="{item, value}">
            <v-btn
              text
              color="primary"
              :to="{name: 'edit-stored-query', params: {storedQueryId: item.storedQueryId}}"
              >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.internal.delete="{item, value}">
            <confirm-button :key="item.storedQueryId">
              <template v-slot:button="confirm">
                <v-btn text small icon color="warning" @click="confirm">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:confirm="cancel">
                <v-item-group multiple>
                  <v-btn text small color="error" @click="deleteStoredQuery(item)">
                    <v-icon>delete</v-icon> {{translate('Delete this stored query?', 'remove.stored-query')}}
                  </v-btn>
                  <v-btn text small icon @click="cancel">
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </v-item-group>
              </template>
            </confirm-button>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import ConfirmButton from '@/components/confirm-button'
export default {
  data: () => ({
    search: '',
  }),
  components: {
    LayoutPage,
    ConfirmButton
  },
  computed: {
    ...mapGetters(['api', 'translate']),
    headers () {
      return [{
        text: '',
        sortable: false,
        value: 'internal.edit'
      }, {
        text: this.translate('Id'),
        sortable: true,
        value: 'storedQueryId'
      }, {
        text: this.translate('Description', 'description'),
        sortable: true,
        value: 'description'
      }, {
        text: '',
        sortable: false,
        value: 'internal.delete'
      }]
    },
  },
  asyncComputed: {
    details: {
      async get () {
        let {storedQueries} = await this.api.storedQueries.getStoredQueries()
        return {
          storedQueries,
          loading: false
        }
      },
      default: {
        loading: true,
        storedQueries: []
      }
    }
  },
  methods: {
    async deleteStoredQuery (item) {
      await this.api.storedQueries.deleteStoredQuery(item)
      let queries = this.details.storedQueries
      let index = queries.indexOf(item)
      queries.splice(index, 1)
    }
  }
}
</script>
